import React, { FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react';
import styles from './fade-in-section.component.module.scss';

type FadeInSectionProps = {
  className?: string;
  speed?: number;
  children?: ReactNode;
}

const FadeInSection: FunctionComponent<FadeInSectionProps> = (props) => {
  const [isVisible, setVisible] = useState(true);
  const ref = useRef<HTMLDivElement>(null);
  const speedStyles: { [key: number]: string } = {
    0: styles.speed0,
    3: styles.speed3,
  }

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting));
    });
    observer.observe(ref.current!);
    return () => observer.unobserve(ref.current!);
  }, []);

  return (
    <div ref={ref} className={`${props.className} ${styles.fadeInSection} ${speedStyles[props.speed ?? 0]} ${isVisible ? styles.isVisible : ''}`}>
      {props.children}
    </div>
  );
};

export default FadeInSection;