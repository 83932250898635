const moment = require(`moment`);

function getPostUri(post) {
  const year = moment(post.date).year();
  const month = moment(post.date).month() + 1;
  const monthName = moment(month, 'MM').format('MM');

  return `/${year}/${monthName}/${post.slug}`;
}

module.exports = {
  getPostUri
};